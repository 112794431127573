import React from 'react';

import { BACKGROUND_IMAGES } from '../../../assets/img/background';
import { ICONS } from '../../../assets/img/icon';

const { smartCity } = BACKGROUND_IMAGES;
const { smartGrid, ecoCity } = ICONS;

const Innovative = () => (
  <>
    <section className="property property-innovative property-innovative__extra content-right">
      <div className="container">
        <div className="property-info">
          <div className="property-info__title">INNOVATIVE
            INFRASTRUCTURE
          </div>
          <div className="property-info__desc">
            Innovative Infrastructure will transform the future of energy and the way we make, move, and manage
            electricity in the future.
          </div>
        </div>
      </div>
      <div className="property-linear" />
    </section>
    <section className="infrastructure-city">
      <div className="container">
        <div className="infrastructure-city__row">
          <div className="infrastructure-city__text">
            <div className="infrastructure-city__text-row">
              The transition to a future sustainable system is
              <span> accelerated by technological and economic advances </span>
              and supported by the alignment of
            </div>
            <div className="infrastructure-city__text-column">
              <div className="infrastructure-city__text-column__row">
                Political
              </div>
              <div className="infrastructure-city__text-column__row">
                Regulatory
              </div>
              <div className="infrastructure-city__text-column__row">
                Social Pressures.
              </div>
            </div>
            <div className="infrastructure-city__text-row">
              According to an analysis by the Energy and Climate Intelligence Unit (ECIU),
              <span> more than $39 T — about 49% of the world’s annual GDP </span>
              — is generated by nations, regions and cities with an actual or intended net-zero target.
            </div>
            <div className="infrastructure-city__text-row">
              The world added a record <span>260 Gigawatts of renewable electricity
                capacity, almost 50% more than in 2019.</span> However, to keep the
              <br />
              global temperature increase to “well below” 2 degrees Celsius, more
              investment in renewables is needed – much more.
            </div>
          </div>
          <div className="infrastructure-city__img">
            <div className="img-container">
              <img src={smartCity} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="keys infrastructure-keys">
      <div className="container">
        <div className="keys-row">
          <div className="keys-title">
            We identify the following investment opportunities:
          </div>
          <div className="keys-block">
            <ul className="keys-list">
              <li className="keys-item">
                <div className="keys-item__icon">
                  <div className="img-container">
                    <svg width="4rem" height="4rem">
                      <use xlinkHref="#buildings" />
                    </svg>
                  </div>
                </div>
                <div className="keys-item__text">
                  Efficient Buildings
                </div>
              </li>
              <li className="keys-item">
                <div className="keys-item__icon">
                  <div className="img-container">
                    <svg width="5.12rem" height="5.12rem">
                      <use xlinkHref="#infrastructure" />
                    </svg>
                  </div>
                </div>
                <div className="keys-item__text">
                  Digital Infrastructure
                </div>
              </li>
              <li className="keys-item">
                <div className="keys-item__icon">
                  <div className="img-container">
                    <svg width="4.18rem" height="4.18rem">
                      <use xlinkHref="#square" />
                    </svg>
                  </div>
                </div>
                <div className="keys-item__text">
                  Intelligent Grid/Storage
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="smart-grid">
      <div className="container">
        <div className="smart-grid__row">
          <div className="smart-grid__text">
            <div className="smart-grid__text-row">
              Simply layering in the latest technologies, while an opportunity,
              <span> fails </span> to adequately <span> address the problem. </span>
              In many parts of the world, electricity infrastructure has suffered from years of <span> chronic underinvestment. </span>
              Combined with an increase in
              <br />
              severity and frequency of extreme weather events, the delivery of
              <span> critical electricity remains unreliable. </span>
            </div>
            <div className="smart-grid__text-row">
              Decentralization, decarbonization, resilience and evolving consumer behaviors require a grid flexibility
              that does not exist in today’s legacy infrastructure. The smart grid of tomorrow will be characterized by
              its decentralized lower emissions electricity structure.
            </div>
          </div>
          <div className="smart-grid__image">
            <div className="img-container">
              <img src={smartGrid} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="about-mobility  about-mobility__city">
      <div className="container">
        <div className="about-mobility__city-bg" />
        <div className="about-mobility__text">
          <div className="about-mobility__text-row">
            The technical challenges posed by variable power generation (wind/solar) require
            developers, operators and regulators to work together to maximize connections
            in the transition to a renewables-based environment.
          </div>
          Electricity grids, underpinned in the past by mechanical principles and
          operational technology (OT) need to make way for electronic systems
          <br /> that run on information technology (IT).
        </div>
      </div>
    </section>
    <section className="smart-grid eco-city">
      <div className="container">
        <div className="smart-grid__row">
          <div className="smart-grid__text">
            <div className="smart-grid__text-row">
              Energy Transition has shifted from a model targeting emissions to one based on economics
              and technology. Further ahead, technological disruption will likely impact the usage and
              requirements of the electrical grids, with breakthroughs creating demand for a more <span>flexible infrastructure.</span>
            </div>
            <div className="smart-grid__text-row">
              One of the most profound changes in tomorrow’s electricity infrastructure will be the development
              of “prosumers” –  consumers of electricity who produce, store, and sometimes sell back their own energy to the grid.
            </div>
            <div className="smart-grid__text-row">
              The advent of prosumers <span>radically challenges</span>  the traditional electric grid infrastructure
              and conventional <span>utility business model.</span>  As for energy transition innovations, a more
              interactive feedback loop will give rise to <span>virtual power plants</span>  and
              <br /> <span>micro-grids,</span>  which in clusters could <span>complement each other</span> and share information.
            </div>
          </div>
          <div className="smart-grid__image">
            <div className="img-container">
              <img src={ecoCity} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="infrastructure-hint">
      <div className="infrastructure-hint-container container">
        <div className="infrastructure-hint__row">
          <div className="infrastructure-hint__title">
            Among the advances that will require significant <br /> investment, in scale:
          </div>
          <div className="infrastructure-hint__icons">
            <ul className="infrastructure-hint__icons-list">
              <li className="infrastructure-hint__icons-item">
                <div className="infrastructure-hint__icons-item__icon">
                  <div className="img-container">
                    <svg width="5.41rem" height="4.31rem">
                      <use xlinkHref="#car-rout" />
                    </svg>
                  </div>
                </div>
                <div className="infrastructure-hint__icons-item__text">
                  <div className="infrastructure-hint__icons-item__text-title">Autonomous vehicles</div>
                  <div>
                    Will impact public transportation, shift traffic patterns, and urban design
                  </div>
                </div>
              </li>
              <li className="infrastructure-hint__icons-item">
                <div className="infrastructure-hint__icons-item__icon">
                  <div className="img-container">
                    <svg width="4.68rem" height="4.68rem">
                      <use xlinkHref="#drone" />
                    </svg>
                  </div>
                </div>
                <div className="infrastructure-hint__icons-item__text">
                  <div className="infrastructure-hint__icons-item__text-title"> Drone development</div>
                  <div>
                    Will take vehicles off the road but will require new methods of air traffic control and monitoring
                  </div>
                </div>
              </li>
              <li className="infrastructure-hint__icons-item">
                <div className="infrastructure-hint__icons-item__icon">
                  <div className="img-container">
                    <svg width="4.06rem" height="4.06rem">
                      <use xlinkHref="#printing" />
                    </svg>
                  </div>
                </div>
                <div className="infrastructure-hint__icons-item__text">
                  <div className="infrastructure-hint__icons-item__text-title">3D printing</div>
                  <div>
                    May shorten manufacturing supply chains and potentially reduce shipping times
                  </div>
                </div>
              </li>
              <li className="infrastructure-hint__icons-item">
                <div className="infrastructure-hint__icons-item__icon">
                  <div className="img-container">
                    <svg width="5.18rem" height="4.6rem">
                      <use xlinkHref="#profit" />
                    </svg>
                  </div>
                </div>
                <div className="infrastructure-hint__icons-item__text">
                  <div className="infrastructure-hint__icons-item__text-title">Evolving consumer behavior paradigms</div>
                  <div>
                    Efficiency, smart pricing and value-added services are adopted
                  </div>
                </div>
              </li>
              <li className="infrastructure-hint__icons-item">
                <div className="infrastructure-hint__icons-item__icon">
                  <div className="img-container">
                    <svg width="4.86rem" height="3.89rem">
                      <use xlinkHref="#software" />
                    </svg>
                  </div>
                </div>
                <div className="infrastructure-hint__icons-item__text">
                  <div className="infrastructure-hint__icons-item__text-title">Software</div>
                  <div>
                    Intelligently and autonomously manage grid stability and distributed energy resources.
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Innovative;
