import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closePopup, openPopup } from '../../../redux/slices/popups.slice';
import { selectIsAuth } from '../../../redux/slices/user.slice';
import { loginThunk } from '../../../redux/thunks/user.thunk';
import { Popup } from '../../UI';
import { POPUPS_IDS } from '../constants/popups.constants';
import { openMobileMenu } from '../../../redux/slices/ui.slice';

const { LOGIN, CONFIRMATION } = POPUPS_IDS;

const LoginPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const popupProps = useSelector(state => state.popups.popupsProps[LOGIN]);
  const isAuth = useSelector(selectIsAuth);

  const { register, handleSubmit, formState: { errors }} = useForm();

  const onClose = () => {
    dispatch(closePopup(LOGIN));
  };

  const afterLogin = () => {
    popupProps?.to && navigate(popupProps.to);
    onClose();
    dispatch(openPopup({ id: CONFIRMATION }));
  };

  const closeMobileMenu = () => {
    dispatch(openMobileMenu(false));
  };

  const onSubmit = (data) => {
    dispatch(loginThunk(data))
      .unwrap()
      .then(() => {
        closeMobileMenu();
      });
  };

  useEffect(() => {
    if (isAuth) {
      afterLogin();
    }
  }, [ isAuth ]);

  return (
    <Popup onClose={onClose}>
      <div className="login-popup">
        <div className="login-popup__header">
          Login
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="login-popup__form">
          <div className="login-popup__block">
            <div className="login-popup__label">
              <div className="login-popup__name">
                Username
              </div>
              <input
                {...register('username', { required: true })}
                className={classNames('login-popup__input', { 'login-popup__input--error': errors.username })}
                type="text" />
            </div>
            <div className="login-popup__label">
              <div className="login-popup__name">
                Password
              </div>
              <input
                {...register('password', { required: true })}
                className={classNames('login-popup__input', { 'login-popup__input--error': errors.password })}
                type="password" />
            </div>
          </div>
          <div className="login-popup__accept">
            <button className="login-popup__accept-btn btn">
              Login
            </button>
          </div>
        </form>

        <button onClick={onClose} className="popup-close">
          <span className="img-container">
            <svg width="1.15rem" height="1.15rem">
              <use xlinkHref="#close" />
            </svg>
          </span>
        </button>
      </div>
    </Popup>
  );
};

export default LoginPopup;
