import React, { useEffect } from 'react';
import { Rings } from 'react-loader-spinner';

const Loader = () => {
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';

    return (() => {
      document.documentElement.style.overflow = 'visible';
      document.body.style.overflow = 'visible';
    });
  }, []);

  return (
    <div className="loaderWrapper">
      <Rings
        height="250"
        width="250"
        color='#0fb52b'
        ariaLabel='loading'
      />
    </div>
  );
};

export default Loader;
