import { BACKGROUND_IMAGES } from '../../../assets/img/background';
import { ICONS } from '../../../assets/img/icon';

const { solar } = ICONS;

const EnergyTransition = () => (
  <>
    <section className="property  property-energy property-energy__extra">
      <div className="container">
        <div className="property-info">
          <div className="property-info__title">Energy Transition</div>
          <div className="property-info__desc">The convergence of policy, economics, social, and environmental needs
            create
            unprecedented, tectonic opportunities to transform the future of energy.
          </div>
        </div>
      </div>
    </section>
    <section className="solar">
      <div className="container">
        <div className="solar-row">
          <div className="solar-column solar-column--info">
            <div className="solar-column__info">
              <div className="solar-column__title">
                The transition to a future sustainable energy system has moved from
                <span> possible </span>
                to
                <span> probable, </span>
              </div>
              <div className="solar-column__desc">
                Driven by the alignment of
                <span> political, regulatory, </span>
                and
                <span> societal pressures </span>
              </div>
              <div className="solar-column__desc">
                Accelerated by
                <span> technological </span>
                and
                <span> economic advances </span>
              </div>
            </div>
            <div className="solar-column__properties">
              <div className="solar-column__properties-title">To meet the Paris Accord of zero net emissions by 2050
              </div>
              <ul className="solar-column__properties-list">
                <li className="solar-column__properties-item">
                  The renewable share of electricity generation needs to
                  quadruple, from less than 25% today to almost 70%.
                </li>
                <li className="solar-column__properties-item">
                  A cost of
                  <span> $110T </span>
                  will be needed globally.
                </li>
                <li className="solar-column__properties-item">
                  A pace to generate
                  <span> 1 Terawatt every two years </span>
                  for the next
                  <span> 30  years </span>
                  must be reached and sustained (it took us 20 years to deploy the first Terawatt of energy from wind
                  and solar).
                </li>
              </ul>
            </div>
          </div>
          <div className="solar-column solar-column--icon">
            <div className="solar-column__icon-img">
              <div className="img-container">
                <img src={solar} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="investments">
      <div className="container">
        <div className="investments-row">
          <div className="investments-title">To meet the challenges ahead to address climate change, we at TECTONIC
            believe
            massive investments are needed to reshape <span>the global energy landscape.</span>
          </div>
          <div className="investments-info">
            <ul className="investments-info__list">
              <li className="investments-info__item">
                <div className="investments-info__item-icon">
                  <div className="img-container">
                    <svg width="3.43rem" height="3.43rem">
                      <use xlinkHref="#renewable" />
                    </svg>
                  </div>
                </div>
                <div className="investments-info__item-text">Renewable energy technologies</div>
              </li>
              <li className="investments-info__item">
                <div className="investments-info__item-icon">
                  <div className="img-container">
                    <svg width="3.9rem" height="4.56rem">
                      <use xlinkHref="#hands" />
                    </svg>
                  </div>
                </div>
                <div className="investments-info__item-text">Energy <br />
                  efficiencies
                </div>
              </li>
              <li className="investments-info__item">
                <div className="investments-info__item-icon">
                  <div className="img-container">
                    <svg width="3.37rem" height="3.37rem">
                      <use xlinkHref="#battery" />
                    </svg>
                  </div>
                </div>
                <div className="investments-info__item-text">Innovative battery technologies</div>
              </li>
              <li className="investments-info__item">
                <div className="investments-info__item-icon">
                  <div className="img-container">
                    <svg width="3.87rem" height="3.87rem">
                      <use xlinkHref="#materials" />
                    </svg>
                  </div>
                </div>
                <div className="investments-info__item-text"> Advances in <br />new materials</div>
              </li>
              <li className="investments-info__item">
                <div className="investments-info__item-icon">
                  <div className="img-container">
                    <svg width="3.25rem" height="3.25rem">
                      <use xlinkHref="#hydrogen" />
                    </svg>
                  </div>
                </div>
                <div className="investments-info__item-text">Use of artificial intelligence (AI) and green hydrogen
                </div>
              </li>
              <li className="investments-info__item">
                <div className="investments-info__item-icon">
                  <div className="img-container">
                    <svg width="4.5rem" height="4.5rem">
                      <use xlinkHref="#satellite" />
                    </svg>
                  </div>
                </div>
                <div className="investments-info__item-text">Satellite imagery technology</div>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </section>
    <section className="world">
      <div className="container">
        <div className="world-row">
          <div className="world-column world-column__img">
            <div className="world-column__image">
              <div className="img-container">
                <img src={BACKGROUND_IMAGES.world} alt="" />
              </div>
            </div>
          </div>
          <div className="world-column world-column__info">
            <div className="world-column__text">
              Business models focused on the
              <span> increased connectivity </span>
              and <span>innovative design of future energy systems</span> offer
              more <span>scalability</span> than traditional models based on manufacturing, even those with a post-sale
              service
              component. Companies focused on supporting and building <span> future distributed energy resources management
                systems (DERMS),</span> the software and the products embedded in these systems,
              offer the best future
              <span> volume growth </span>
              and
              <span> increased profits </span>
              from scalability.
            </div>
            <div className="world-column__text">
              As a result of significant adoption of electrification, especially by the
              <span> transportation and industrial sectors </span>
              of the economy, a transformed future energy scenario means <span>electricity demand</span> in major
              economies
              around the world will <span>approximately double from today’s level by 2050,</span> even though planned
              emissions
              remain flat – a <span>massive change</span> from today’s energy situation.
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="energy-info">
      <div className="container">
        <div className="energy-info__bg" />
        <div className="energy-info__block">
          <div className="energy-info__text">
            <span>Energy behaviors, knowledge</span> and <span>
              decision making</span> will gradually, then suddenly, <span>vault</span> to the front of mind for
            individuals and households.
          </div>
          <div className="energy-info__text">
            Identifying ways to address <span>waste energy through waste-heat recovery systems</span> will be one of the
            key
            climate–tech initiatives for decades. As the saying goes, "consumers don’t need to buy sunshine." We predict
            the <span> need to buy</span> electricity <span>will diminish
            </span> with increased innovation. Potential rooftop solar puts the power –
            literally – in the <span>hands of the customer.</span>
          </div>
          <div className="energy-info__text">
            As we switch to <span>clean energy, electric transportation,
            </span> and <span>buildings,</span> efficiency measures will remain
            essential and become more cost–effective.
          </div>
        </div>
      </div>

    </section>
  </>
);

export default EnergyTransition;
