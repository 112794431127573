import { NavLink } from 'react-router-dom';

const TermsConditions = () => (
  <section className="terms">
    <div className="terms-container container">
      <h2 className="terms-caption">
        Terms of Use
      </h2>
      <ul className="terms-list">
        <li className="terms-item">
          <div className="terms-item__caption">
            "General Legend on Terms of Use
          </div>
          <div className="terms-item__desc">
            <div className="terms-item__desc-line">
              "BY ACCESSING THIS SITE, YOU SIGNIFY YOUR AGREEMENT WITH, AND UNDERSTANDING OF, THE FOLLOWING TERMS OF USE
              AND LEGAL INFORMATION PERTAINING TO BOTH THIS SITE AND ANY MATERIAL ON IT. THIS SITE IS OFFERED TO YOU
              CONDITIONED ON YOUR ACCEPTANCE WITHOUT MODIFICATION OF THE TERMS, CONDITIONS, AND NOTICES CONTAINED
              HEREIN. YOUR USE OF THIS SITE CONSTITUTES YOUR AGREEMENT TO ALL SUCH TERMS, CONDITIONS AND NOTICES.
              TECTONIC INVESTORS RESERVES THE RIGHT TO CHANGE THE TERMS, CONDITIONS, AND NOTICES UNDER WHICH THIS SITE
              IS OFFERED.
            </div>
            <div className="terms-item__desc-line">
              ALL MATERIALS ON THIS SITE ARE MEANT TO BE REVIEWED IN THEIR ENTIRETY, INCLUDING ANY FOOTNOTES, LEGAL
              DISCLAIMERS, RESTRICTIONS OR DISCLOSURES, AND ANY COPYRIGHT OR PROPRIETARY NOTICES. ANY DISCLAIMERS,
              RESTRICTIONS, DISCLOSURES OR HEDGE CLAUSES APPLY TO ANY PARTIAL DOCUMENT OR MATERIAL IN THE SAME MANNER AS
              THEY DO THE WHOLE AND WILL BE DEEMED INCORPORATED IN THE PORTION OF ANY MATERIAL OR DOCUMENT THAT YOU
              CONSULT OR DOWNLOAD."
            </div>
          </div>
        </li>
        <li className="terms-item">
          <div className="terms-item__caption">
            "Ownership of Site and Copyright
          </div>
          <div className="terms-item__desc">
            <div className="terms-item__desc-line">
              THE TECTONIC INVESTORS WEBSITE IS OWNED BY TECTONIC INVESTORS. THE TECTONIC INVESTORS WEBSITE AND ANY AND
              ALL ACCOMPANYING SCREENS, INFORMATION, MATERIALS, USER DOCUMENTATION, USER INTERFACES, IMAGES,
              ARRANGEMENTS OF INFORMATION, RELATED SOFTWARE AND OTHER PROPRIETARY PROPERTY OF TECTONIC INVESTORS OR ITS
              LICENSORS ACCESSIBLE VIA THE WEBSITE IS AND SHALL REMAIN THE EXCLUSIVE PROPERTY OF TECTONIC INVESTORS AND
              ITS LICENSORS, AS THE CASE MAY BE. ALL RIGHTS TO THE WEBSITE REMAIN WITH TECTONIC INVESTORS OR ITS
              LICENSORS. THIS SITE IS FOR YOUR PERSONAL AND NON-COMMERCIAL USE. YOU MAY NOT MODIFY, COPY, DISTRIBUTE,
              TRANSMIT, DISPLAY, PERFORM, REPRODUCE, PUBLISH, LICENSE, CREATE DERIVATIVE WORKS FROM, TRANSFER OR SELL
              ANY INFORMATION, SOFTWARE, PRODUCTS OR SERVICES OBTAINED FROM THIS SITE."
            </div>
          </div>
        </li>
        <li className="terms-item">
          <div className="terms-item__caption">
            "Unauthorized Access
          </div>
          <div className="terms-item__desc">
            <div className="terms-item__desc-line">
              THIS SITE IS NOT ABSOLUTELY PROTECTED AGAINST UNAUTHORIZED THIRD PARTIES. YOU ACKNOWLEDGE THAT ANY
              INFORMATION PROVIDED THROUGH THE INTERNET MAY BE POTENTIALLY ACCESSED BY UNAUTHORIZED THIRD PARTIES.
              ALTHOUGH TECTONIC INVESTORS WILL MAKE REASONABLE EFFORTS TO PROTECT THE PRIVACY OF USERS OF THIS SITE, NO
              GUARANTEE CAN BE MADE THAT UNAUTHORIZED THIRD PARTIES WILL NOT ACCESS THE INFORMATION CONTAINED ON THE
              WEBSITE. YOU ACKNOWLEDGE THAT TECTONIC INVESTORS IS NOT RESPONSIBLE FOR NOTIFYING YOU THAT UNAUTHORIZED
              THIRD PARTIES HAVE GAINED SUCH ACCESS OR THAT ANY DATA HAS BEEN OTHERWISE COMPROMISED DURING TRANSMISSION
              ACROSS COMPUTER NETWORKS OR TELECOMMUNICATIONS FACILITIES, INCLUDING, BUT NOT LIMITED TO, THE INTERNET."
            </div>
          </div>
        </li>
        <li className="terms-item">
          <div className="terms-item__caption">
            "Passwords and Security
          </div>
          <div className="terms-item__desc">
            <div className="terms-item__desc-line">
              YOU ARE RESPONSIBLE FOR THE CONFIDENTIALITY AND USE OF YOUR PASSWORD. YOUR PASSWORD IS AN IMPORTANT MEANS
              OF PROTECTION FOR YOU. YOU AGREE TO CONTACT US IMMEDIATELY IF YOU BELIEVE THAT AN UNAUTHORIZED PERSON HAS
              OBTAINED ACCESS TO YOUR PASSWORD."
            </div>
          </div>
        </li>
        <li className="terms-item">
          <div className="terms-item__caption">
            "Links
          </div>
          <div className="terms-item__desc">
            <div className="terms-item__desc-line">
              TECTONIC INVESTORS HAS NOT REVIEWED UNAFFILIATED SITES LINKED TO THIS SITE, IF ANY, AND IS NOT RESPONSIBLE
              FOR THE CONTENT OF OFF-SITE PAGES OR ANY OTHER SITE LINKED OR LINKING TO THE SITE. YOUR LINKING TO ANY
              OFF-SITE PAGES OR OTHER SITES IS AT YOUR OWN RISK. TECTONIC INVESTORS MAKES NO REPRESENTATIONS WHATSOEVER
              ABOUT THE OPINIONS OF ANY THIRD PARTY APPEARING ON A LINKED SITE, NEITHER REGULARLY MONITORS NOR HAS
              CONTROL OVER THE CONTENTS OF SUCH SITES, AND DOES NOT ENDORSE, AND DISCLAIMS ALL RESPONSIBILITY FOR, THE
              CONTENT OF SUCH STATEMENTS OR WEBSITES."
            </div>
          </div>
        </li>
        <li className="terms-item">
          <div className="terms-item__caption">
            "Privacy Policy
          </div>
          <div className="terms-item__desc">
            <div className="terms-item__desc-line">
              THE USER RECOGNIZES THAT NON-PUBLIC INFORMATION CONCERNING THE USER DISCLOSED BY THE USER TO TECTONIC
              INVESTORS, THE TECTONIC VISION MASTER FUND, OR OTHER AGENTS OF EITHER (THE "INFORMATION") (SUCH AS THE
              USER’S NAME, ADDRESS, SOCIAL SECURITY NUMBER, ASSETS AND INCOME) (I) MAY BE DISCLOSED TO THE FUNDS’
              ADMINISTRATOR, IF ANY, INVESTMENT MANAGER, ATTORNEYS, ACCOUNTANTS AND AUDITORS IN FURTHERANCE OF THE
              FUNDS’ BUSINESS AND TO OTHER SERVICE PROVIDERS SUCH AS BROKERS WHO MAY HAVE A NEED FOR THE INFORMATION IN
              CONNECTION WITH PROVIDING SERVICES TO THE FUNDS, (II) TO THIRD PARTY SERVICE PROVIDERS OR FINANCIAL
              INSTITUTIONS WHO MAY BE PROVIDING MARKETING SERVICES TO THE FUNDS PROVIDED THAT SUCH PERSONS MUST AGREE TO
              PROTECT THE CONFIDENTIALITY OF THE INFORMATION AND USE THE INFORMATION ONLY FOR THE PURPOSES OF PROVIDING
              SERVICES TO THE FUNDS AND (III) AS OTHERWISE REQUIRED OR PERMITTED BY LAW. THE TECTONIC VISION MASTER FUND
              AND INVESTMENT MANAGER RESTRICT ACCESS TO THE INFORMATION TO THEIR EMPLOYEES WHO NEED TO KNOW THE
              INFORMATION TO PROVIDE SERVICES TO TECTONIC VISION MASTER FUND, AND MAINTAIN PHYSICAL, ELECTRONIC AND
              PROCEDURAL SAFEGUARDS THAT COMPLY WITH U.S. FEDERAL STANDARDS TO GUARD THE INFORMATION."
            </div>
          </div>
        </li>
        <li className="terms-item">
          <div className="terms-item__caption">
            "Assistance
          </div>
          <div className="terms-item__desc">
            <div className="terms-item__desc-line">
              IF YOU NEED ASSISTANCE WITH THE WEBSITE, OR IF YOU NEED TO COMMUNICATE WITH TECTONIC INVESTORS, YOU MAY
              CONTACT BERT VAN DER WALT AT BERT@TECTONICINVESTORS.COM."
            </div>
          </div>
        </li>
        <li className="terms-item">
          <div className="terms-item__caption">
            "No Warranty or Reliance
          </div>
          <div className="terms-item__desc">
            <div className="terms-item__desc-line">
              THE WEBSITE, INCLUDING INFORMATION AND MATERIALS CONTAINED IN THE WEBSITE, TEXT, GRAPHICS, SOFTWARE, LINKS
              AND OTHER ITEMS ARE PROVIDED "AS IS," "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
              IMPLIED, TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW. WITHOUT LIMITATION, TECTONIC
              INVESTORS DOES NOT WARRANT THE ACCURACY, ADEQUACY, COMPLETENESS, RELIABILITY, TIMELINESS OR AVAILABILITY
              OF THE WEBSITE OR ANY INFORMATION ON THIS SITE, AND EXPRESSLY DISCLAIMS LIABILITY FOR ERRORS OR OMISSIONS
              IN THE WEBSITE. THERE IS NO WARRANTY OF MERCHANTIBILITY, NO WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE,
              NO WARRANTY OF NON-INFRINGEMENT, NO WARRANTY OF ANY KIND, IMPLIED, EXPRESSED OR STATUTORY, IN CONJUNCTION
              WITH THE WEBSITE. ANY CONTENTS ON THIS SITE ARE SUBJECT TO CHANGE WITHOUT NOTICE. TECTONIC INVESTORS
              FURTHER ASSUMES NO RESPONSIBILITY FOR, AND MAKES NO WARRANTIES THAT, FUNCTIONS CONTAINED AT THIS SITE WILL
              BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER THAT MAKES
              IT AVAILABLE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. PLEASE NOTE THAT SOME JURISDICTIONS DO
              NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO
              YOU."
            </div>
          </div>
        </li>
        <li className="terms-item">
          <div className="terms-item__caption">
            Limitation of Liability
          </div>
          <div className="terms-item__desc">
            <div className="terms-item__desc-line">
              IN NO EVENT WILL TECTONIC INVESTORS BE LIABLE FOR ANY DAMAGES, OR FOR REPAIRS OR CORRECTIONS THAT MUST BE
              PERFORMED, TO OR ON YOUR COMPUTER, PERSON OR OTHER PROPERTY, INCLUDING, WITHOUT LIMITATION, DIRECT OR
              INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOSSES OR EXPENSES ARISING IN CONNECTION WITH THE
              WEBSITE OR USE THEREOF OR THE INABILITY BY ANY PARTY TO USE SUCH SITE, OR IN CONNECTION WITH ANY FAILURE
              OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS
              OR LINE OR SYSTEM FAILURE, EVEN IF TECTONIC INVESTORS, OR REPRESENTATIVES THEREOF, ARE ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES, LOSSES OR EXPENSES."
            </div>
          </div>
        </li>
      </ul>
      <div className="terms-accept">
        <NavLink className="terms-accept__btn" to="/">
          Ok
        </NavLink>
      </div>
    </div>
  </section>
);

export default TermsConditions;
