import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Navbar from '../Navbar/Navbar';
import { openMobileMenu, selectIsOpenMobileMenu } from '../../redux/slices/ui.slice';

const whiteHeaders = [ '/', '/portfolio', '/investment/innovative-infrastructure', '/investment/future-mobility' ];

const Header = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const headerRef = useRef(null);
  const isOpenMobileMenu = useSelector(selectIsOpenMobileMenu);

  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  useEffect(() => {
    const header = headerRef.current;

    const hideHeader = () => {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        header.classList.add('active');
      } else {
        header.classList.remove('active');
      }
    };

    if (isDesktop) {
      window.addEventListener('scroll', hideHeader);
      return () => window.removeEventListener('scroll', hideHeader);
    }
  }, [ isDesktop ]);

  return (
    <header
      ref={headerRef}
      className={classNames('header', { 'header-color__black': !whiteHeaders.includes(pathname) })}>
      <div className="container">
        <NavLink className="header-logo" to="/">
          <span className="img-container">
            <svg className="logo" width="29.3rem" height="5.375rem">
              <use xlinkHref="#logo" />
            </svg>
          </span>
        </NavLink>
        <Navbar />
        <button
          className={classNames('menu-burger', { active: isOpenMobileMenu })}
          type="button"
          onClick={() => {
            dispatch(openMobileMenu(!isOpenMobileMenu));
          }}>
          <span className="menu-burger__row" />
        </button>
      </div>
    </header>
  );
};

export default Header;
