import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { INVESTMENT_ROUTES, INVESTMENT_CHILDREN_ROUTES } from '../../configs/routes.configs';
import { openPopup } from '../../redux/slices/popups.slice';
import { resetUser, selectIsAuth, selectUsername } from '../../redux/slices/user.slice';
import { POPUPS_IDS } from '../Popup/constants/popups.constants';
import { openMobileMenu, selectIsOpenMobileMenu } from '../../redux/slices/ui.slice';

const Navbar = ({ footer }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const username = useSelector(selectUsername);
  const isOpenMobileMenu = useSelector(selectIsOpenMobileMenu);

  const isDesktop = useMediaQuery({
    query: '(min-width: 1025px)',
  });

  const [ activeDropdown, setActiveDropdown ] = useState(null);

  const toggleDropdown = (value) => {
    if (isDesktop) return;
    setActiveDropdown(prev => (prev === value ? null : value));
  };

  const closeMobileMenu = () => {
    dispatch(openMobileMenu(false));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setActiveDropdown(null);
  }, [ pathname ]);

  useEffect(() => {
    if (isOpenMobileMenu) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'visible';
      document.body.style.overflow = 'visible';
      setActiveDropdown(null);
    }
  }, [ isOpenMobileMenu ]);

  useEffect(() => {
    if (isDesktop && activeDropdown) {
      setActiveDropdown(null);
    }
  }, [ isDesktop ]);

  return (
    <div className={classNames('nav', { active: isOpenMobileMenu })}>
      <ul className="nav-list">
        <li className="nav-item">
          <NavLink
            to="/"
            className={({ isActive }) => classNames('nav-link', { 'nav-link--active': isActive })}
            onClick={() => dispatch(openMobileMenu(false))}
          >
            home
          </NavLink>
        </li>
        {isAuth && <>
          <li className="nav-item nav-item--dropdown">
            <div
              onClick={() => toggleDropdown(INVESTMENT_ROUTES.name)}
              className={classNames(
                'nav-link',
                {
                  'nav-link--active': pathname.includes(INVESTMENT_ROUTES.path),
                  'nav-link--dropdown': INVESTMENT_ROUTES.name === activeDropdown,
                },
              )}>
              {INVESTMENT_ROUTES.name}
            </div>
            <div className={classNames('nav-dropdown', { 'nav-dropdown--footer': footer })}>
              <ul className="nav-dropdown__list">
                {INVESTMENT_CHILDREN_ROUTES.map(route => (
                  <li key={route.name} className="nav-dropdown__item">
                    <NavLink
                      className={({ isActive }) => classNames('nav-dropdown__link', { 'nav-dropdown__link--active': isActive })}
                      to={`${INVESTMENT_ROUTES.path}/${route.path}`}
                      onClick={() => dispatch(openMobileMenu(false))}
                    >
                      {route.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <NavLink
              to="/portfolio"
              className={({ isActive }) => classNames('nav-link', { 'nav-link--active': isActive })}
              onClick={() => dispatch(openMobileMenu(false))}
            >
              Portfolio Framework
            </NavLink>
          </li>
        </>}
        <li className="nav-item">
          <NavLink
            to="/contact"
            className={({ isActive }) => classNames('nav-link', { 'nav-link--active': isActive })}
            onClick={() => dispatch(openMobileMenu(false))}
          >
            Contact
          </NavLink>
        </li>
        {isAuth
          ? (<li className="nav-item nav-item--dropdown">
            <div
              onClick={() => toggleDropdown('logout')}
              className={classNames('nav-link', {
                'nav-link--dropdown': activeDropdown === 'logout',
              })}>
              <span className="nav-link__icon">
                <span className="img-container">
                  <svg width="1.125rem" height="1.125rem">
                    <use xlinkHref="#user" />
                  </svg>
                </span>
              </span>
              <span>
                {username}
              </span>
            </div>
            <div className={classNames('nav-dropdown', { 'nav-dropdown--footer': footer })}>
              <ul className="nav-dropdown__list">
                <li className="nav-dropdown__item">
                  <button
                    className="nav-dropdown__link"
                    onClick={() => {
                      dispatch(resetUser());
                      closeMobileMenu();
                    }}
                    type="button">
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </li>
          )
          : <li onClick={() => dispatch(openPopup({ id: POPUPS_IDS.LOGIN }))} className="nav-item">
            <button type="button" className='nav-link'>
              Login
            </button>
          </li>}
      </ul>
    </div>
  );
};

export default Navbar;
