import axios from 'axios';
import classNames from 'classnames';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { BACKGROUND_IMAGES } from '../../assets/img/background';
import Loader from '../../components/UI/Loader/Loader';

const Contact = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const {
    register, handleSubmit, formState: { errors }, reset,
  } = useForm();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = async (data) => {
    if (!executeRecaptcha) {
      toast('Please try later', { type: 'error' });
      return;
    }
    try {
      setIsLoading(true);
      const token = await executeRecaptcha();
      await axios.post('/send-mail', { ...data, token });
      reset();
      toast('Success', { type: 'success' });
    } catch (e) {
      toast('Please try later', { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    handleReCaptchaVerify(data);
  };

  return (
    <section className="contact">
      {isLoading && <Loader />}
      <div className="container contact-container">
        <div className="contact-row">
          <div className="contact-column contact-column--form">
            <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
              <div className="contact-form__line">
                <label className="contact-form__label">
                  <input
                    {...register('name', { required: true })}
                    className={classNames('contact-form__input default-input', { 'default-input--error': errors.name })}
                    type="text"
                    placeholder="Full Name"
                    name="name" />
                </label>
                <label className="contact-form__label">
                  <input
                    {...register('phone', { required: true })}
                    className={classNames('contact-form__input default-input', { 'default-input--error': errors.phone })}
                    type="text"
                    placeholder="Phone Number"
                    name="phone" />
                </label>
              </div>
              <div className="contact-form__line">
                <label className="contact-form__label">
                  <input
                    {...register('email', { required: true })}
                    className={classNames('contact-form__input default-input', { 'default-input--error': errors.email })}
                    type="email"
                    placeholder="Email Address"
                    name="email" />
                </label>
                <label className="contact-form__label">
                  <input
                    {...register('subject', { required: true })}
                    className={classNames('contact-form__input default-input', { 'default-input--error': errors.subject })}
                    type="text"
                    placeholder="Subject"
                    name="subject" />
                </label>
              </div>
              <div className="contact-form__line">
                <label className="contact-form__label">
                  <textarea
                    className={classNames('contact-form__textarea default-input', { 'default-input--error': errors.text })}
                    name="text"
                    placeholder="Message"
                    {...register('text', { required: true })} />
                </label>
              </div>
              <div className="contact-form__line">
                <button className="contact-form__accept btn" type="submit">
                  Send
                </button>
                {isLoading}
              </div>
            </form>
          </div>
          <div className="contact-column contact-column--bg">
            <div className="contact-bg">
              <div className="img-container">
                <img src={BACKGROUND_IMAGES.contact} alt="Contact Background" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
