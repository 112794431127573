import accumulator from './accumulator.svg';
import arrow from './arrow.svg';
import battery from './battery.svg';
import bioplastic from './bioplastic.svg';
import buildings from './buildings.svg';
import bus from './bus.svg';
import carRout from './car-rout.svg';
import changes from './changes.svg';
import charge from './charge.svg';
import drone from './drone.svg';
import ecoCar from './eco-car.svg';
import ecoCity from './eco-city.svg';
import fb from './fb.svg';
import hands from './hands.svg';
import hydrogen from './hydrogen.svg';
import infrastructure from './infrastructure.svg';
import linkedin from './linkedin.svg';
import logoDark from './logo-dark.svg';
import logoFooter from './logo-footer.svg';
import logo from './logo.svg';
import manufactoring from './manufactoring.svg';
import map from './map.svg';
import material from './material.svg';
import materials from './materials.svg';
import natural from './natural.svg';
import printing from './printing.svg';
import profit from './profit.svg';
import recycleMin from './recycle-min.svg';
import recycle from './recycle.svg';
import renewable from './renewable.svg';
import satellite from './satellite.svg';
import smartGrid from './smart-grid.svg';
import software from './software.svg';
import solar from './solar.svg';
import square from './square.svg';
import twitter from './twitter.svg';
import author from './author.svg';

export const ICONS = {
  accumulator,
  arrow,
  battery,
  bioplastic,
  buildings,
  bus,
  carRout,
  changes,
  charge,
  drone,
  ecoCar,
  ecoCity,
  fb,
  hands,
  hydrogen,
  infrastructure,
  linkedin,
  logoDark,
  logoFooter,
  logo,
  manufactoring,
  map,
  material,
  materials,
  natural,
  printing,
  profit,
  recycleMin,
  recycle,
  renewable,
  satellite,
  smartGrid,
  software,
  solar,
  square,
  twitter,
  author,
};
