import { BACKGROUND_IMAGES } from '../../../assets/img/background';

const FutureMobility = () => (
  <>
    <section className="property property-mobility property-mobility__extra">
      <div className="container">
        <div className="property-info">
          <div className="property-info__title">FUTURE MOBILITY</div>
          <div className="property-info__desc">
            The growth rate in mobility will be nothing short of staggering over the next decade.
          </div>
        </div>
      </div>
    </section>
    <section className="mobility-info">
      <div className="container">
        <div className="mobility-info__row">
          <div className="mobility-info__text">
            <div className="mobility-info__text-row">
              Mobility – on the cusp of another leap forward – is concurrently tasked with solving urgent
              <span> urbanization </span>
              and <span>environmental issues.</span>
            </div>
            <div className="mobility-info__text-column__title">
              Common interests of <span>government, business,</span>  and <span>consumers</span>  are aligning today, allowing for:
            </div>
            <div className="mobility-info__text-column">
              <div className="mobility-info__text-column__row"> Greater investment</div>
              <div className="mobility-info__text-column__row">New mobility products</div>
              <div className="mobility-info__text-column__row"> Unprecedented investment opportunities.</div>
            </div>
          </div>
          <div className="mobility-info__icon">
            <div className="img-container">
              <img src={BACKGROUND_IMAGES.carWhite} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="about-mobility">
      <div className="container">
        <div className="about-mobility__bg" />
        <div className="about-mobility__text">
          <div className="about-mobility__text-row">
            In countries with low levels of private vehicle ownership and inadequate public transit, the contribution of
            <span> shared mobility services </span>  is higher. We estimate shared mobility services accounted for about <span>5% of total
              kilometers traveled</span>  by the global passenger vehicle fleet
            <span>in 2019,</span>  and around <span>3% in 2020.</span>  By <span>2025,</span>  shared
            mobility’s annual passenger vehicle kilometers traveled globally is estimated to <span>exceed 6%</span>  for the first
            time. Even a modest 10% reduction in total kilometers traveled by car globally in 2050 can make the task of
            achieving net-zero much easier.
          </div>
          We forecast the global air taxi passenger mobility market to grow from a burgeoning <span> $1.5 B</span> in revenue in 2025
          to <span> $150.9 B</span> in revenue in 2035.
        </div>
      </div>
    </section>
    <section className="economy-mobility">
      <div className="container">
        <div className="economy-mobility__row">
          <div className="economy-mobility__text">
            <div className="economy-mobility__text-row">
              Mobility is poised to reshape as a sector which will likely <span>contribute 1/5th of global economic activity. </span>
              Further, we see a <span> tectonic sea-change</span> in
              this industry with the arrival of <span>new products</span>  and services.
            </div>
            TECTONIC Investors are interested in the following areas:
          </div>
          <div className="economy-mobility__icons">
            <ul className="economy-mobility__icons-list">
              <li className="economy-mobility__icons-item">
                <div className="economy-mobility__icons-item__icon">
                  <div className="img-container">
                    <svg width="5.215rem" height="4.33rem">
                      <use xlinkHref="#eco-car" />
                    </svg>
                  </div>
                </div>
                <div className="economy-mobility__icons-item__text">
                  EV and H Vehicles
                </div>
              </li>
              <li className="economy-mobility__icons-item">
                <div className="economy-mobility__icons-item__icon">
                  <div className="img-container">
                    <svg width="4.45rem" height="4.37rem">
                      <use xlinkHref="#charge" />
                    </svg>
                  </div>
                </div>
                <div className="economy-mobility__icons-item__text">
                  EV Charging Infrastructure
                </div>
              </li>
              <li className="economy-mobility__icons-item">
                <div className="economy-mobility__icons-item__icon">
                  <div className="img-container">
                    <svg width="4.43rem" height="3.7rem">
                      <use xlinkHref="#accumulator" />
                    </svg>
                  </div>
                </div>
                <div className="economy-mobility__icons-item__text">
                  Batteries, Fuels, and Biofuels
                </div>
              </li>
              <li className="economy-mobility__icons-item">
                <div className="economy-mobility__icons-item__icon">
                  <div className="img-container">
                    <svg width="5.215rem" height="4.158rem">
                      <use xlinkHref="#car-rout" />
                    </svg>
                  </div>
                </div>
                <div className="economy-mobility__icons-item__text">
                  Autonomous Vehicles
                </div>
              </li>
              <li className="economy-mobility__icons-item">
                <div className="economy-mobility__icons-item__icon">
                  <div className="img-container">
                    <svg width="5.18rem" height="2.84rem">
                      <use xlinkHref="#bus" />
                    </svg>
                  </div>
                </div>
                <div className="economy-mobility__icons-item__text">
                  Other Mobility
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="about-mobility  about-mobility__extra">
      <div className="container">

        <div className="about-mobility__text">
          <div className="about-mobility__text-row">
            We expect the steepest part of EV adoption in the next decade. To achieve net-zero by 2050, EVs need to go
            from <span>~10 M</span>  vehicles on
            <br /> the road today to at least <span>200 M</span>  by the
            <span>end of 2030,</span>  representing <br /> <span>60% of global new passenger
              vehicle sales</span>  by 2030.
          </div>
          <div className="about-mobility__text-row">
            Under a net-zero scenario, the world will need more than an <br /> estimated <span>$11 T in capital investment</span>  in
            EVs and related
            <br /> infrastructure over the next few decades.
          </div>
          The sector is expected to grow to <span>$26.6 T</span>  by <span>2030
          </span>  - nearly <span>double <br /> the size</span>  of the current market, which
          is about <span>$15 T.</span>
        </div>
      </div>
    </section>
    <section className="mobility-skeleton">
      <div className="container">
        <div className="mobility-skeleton__content">
          <div className="mobility-skeleton__text">
            Urbanization is expected to continue its steady march around the world leading to increased concerns about
            congestion and worsening urban air quality, while bringing new solutions such as <span>micro mobility</span>  and <span>urban
              air taxis</span>  into the fold.
          </div>
          <div className="mobility-skeleton__img">
            <div className="img-container">
              <img src={BACKGROUND_IMAGES.carSkeleton} alt="Car" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="mobility-hint">
      <div className="container">
        <div className="mobility-hint__bg" />
        <div className="mobility-hint__text">
          <div className="mobility-hint__text-row">
            <span>By 2030,</span>  the sector will likely expand its contribution to GDP to
            comprise one-fifth of global economic activity, with a compound annual growth rate
            of <span>4.5%.</span>  We see new products and services growing most rapidly. <span>
              Mobility services, systems, and data</span>  are projected to increase from less than
            <span> $1 T to $3.5 T in 2030</span>  as cars get <span>connected to everything around them.</span>
          </div>
          We are interested in <span>new areas of mobility</span>  that we believe have <span>
            massive potential,</span> such as <span>micro mobility</span>  which is projected
          to grow to <span>$95 B by 2025</span> and <span>$447 B by 2040.</span>
        </div>
      </div>
    </section>
  </>
);

export default FutureMobility;
