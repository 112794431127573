import { Navigate } from 'react-router-dom';
import Contact from '../pages/Contact/Contact';
import Home from '../pages/Home/Home';
import CircularEconomy from '../pages/Investment/CircularEconomy/CircularEconomy';
import EnergyTransition from '../pages/Investment/EnergyTransition/EnergyTransition';
import FutureMobility from '../pages/Investment/FutureMobility/FutureMobility';
import Innovative from '../pages/Investment/Innovative/Innovative';
import Portfolio from '../pages/Portfolio/Portfolio';
import TermsConditions from '../pages/TermsConditions/TermsConditions';

const HOME_ROUTE = {
  path: '/',
  name: 'Home',
  element: <Home />,
};

const CONTACT_ROUTE = {
  path: '/contact',
  name: 'Contact',
  element: <Contact />,
};

const TERMS_ROUTE = {
  path: '/terms-conditions',
  name: 'Terms & Conditions',
  element: <TermsConditions />,
};

export const INVESTMENT_CHILDREN_ROUTES = [
  {
    path: 'energy-transtion',
    name: 'Energy Transition',
    element: <EnergyTransition />,
  },
  {
    path: 'future-mobility',
    name: 'Future Mobility',
    element: <FutureMobility />,
  },
  {
    path: 'circular-economy',
    name: 'Circular Economy',
    element: <CircularEconomy />,
  },
  {
    path: 'innovative-infrastructure',
    name: 'Innovative Infrastructure',
    element: <Innovative />,
  },
];

export const INVESTMENT_ROUTES = {
  path: '/investment',
  name: 'Investment Themes',
  children: [
    {
      index: true,
      element: <Navigate to='energy-transtion' replace />,
    },
    ...INVESTMENT_CHILDREN_ROUTES,
    {
      path: '*',
      element: <Navigate to="energy-transtion" replace />,
    },
  ],
};

export const LOGOUT_ROUTES = [
  HOME_ROUTE,
  CONTACT_ROUTE,
  TERMS_ROUTE,
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
];

export const LOGIN_ROUTES = [
  HOME_ROUTE,
  INVESTMENT_ROUTES,
  TERMS_ROUTE,
  {
    path: '/portfolio',
    name: 'Portfolio Framework',
    element: <Portfolio />,
  },
  CONTACT_ROUTE,
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
];
