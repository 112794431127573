import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button } from '../../components/UI';
import { BACKGROUND_IMAGES } from '../../assets/img/background';
import { ICONS } from '../../assets/img/icon';
import { PERSON_IMAGES } from '../../assets/img/persons';
import { selectIsAuth } from '../../redux/slices/user.slice';
import { openPopup } from '../../redux/slices/popups.slice';
import { POPUPS_IDS } from '../../components/Popup/constants/popups.constants';

const { statistic, vision, philosophy } = BACKGROUND_IMAGES;
const { Jeffrey, Bert } = PERSON_IMAGES;

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = useSelector(selectIsAuth);

  const changePage = (to) => {
    isAuth ? navigate(to) : dispatch(openPopup({ id: POPUPS_IDS.LOGIN, to }));
  };

  return (
    <div className="wrapper-inner">
      <section className="changes">
        <div className="container">
          <div className="changes-row">
            <div className="changes-column changes-column__info">
              <div className="changes-column__title">Tectonic Changes</div>
              <div className="changes-column__capture">The World at a Watershed Moment</div>
              <div className="changes-column__par">Capturing Tectonic Opportunities in a Changing World</div>
              <div className="changes-column__desc ">
                <div> We at TECTONIC Investors believe that we are at the <span>precipice of transformational changes</span> happening
                  in our world.
                </div>
                <div>They are already occurring and not only expected to continue, but to <span>vastly accelerate</span> over the
                  coming years.
                </div>
              </div>
              <NavLink className="changes-column__button" to="/contact">
                <span>Contact Us</span>
              </NavLink>
            </div>
            <div className="changes-column changes-column__img">
              <div className="changes-column__img-icon">
                <div className="img-container">
                  <img src={ICONS.map} alt="Map" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="property  property-energy">
        <div className="container">
          <div className="property-info">
            <div className="property-info__title">Energy Transition</div>
            <div className="property-info__desc">The convergence of policy, economics, social, and environmental needs create
              unprecedented, tectonic opportunities to transform the future of energy.
            </div>
          </div>
          <Button onClick={() => changePage('/investment/energy-transtion')} next>Explore</Button>
        </div>
      </section>
      <section className="property property-economy content-right">
        <div className="container">
          <div className="property-info">
            <div className="property-info__title">Circular Economy</div>
            <div className="property-info__desc">
              We have before us the biggest opportunity to transform production and consumption since the First Industrial
              Revolution 250 years ago.
            </div>
          </div>
          <Button onClick={() => changePage('/investment/circular-economy')} next>Explore</Button>
        </div>
        <div className="property-linear" />
      </section>
      <section className="property property-mobility">
        <div className="container">
          <div className="property-info">
            <div className="property-info__title">FUTURE MOBILITY</div>
            <div className="property-info__desc">
              The growth rate in mobility will be nothing short of staggering over the next decade.
            </div>
          </div>
          <Button onClick={() => changePage('/investment/future-mobility')} next>Explore</Button>
        </div>
      </section>
      <section className="property property-innovative content-right">
        <div className="container">
          <div className="property-info">
            <div className="property-info__title">INNOVATIVE
              INFRASTRUCTURE
            </div>
            <div className="property-info__desc">
              Innovative Infrastructure will transform the future of energy and the way we make, move, and manage
              electricity in the future.
            </div>
          </div>
          <Button onClick={() => changePage('/investment/innovative-infrastructure')} next>Explore</Button>
        </div>
        <div className="property-linear" />
      </section>
      {isAuth && <section className="why-us">
        <div className="container">
          <div className="why-us__row">
            <div className="why-us__title">
              Why
              <br />
              TECTONIC
              <br />
              Investors?
            </div>
            <div className="why-us__info">
              <div className="why-us__info-row">“The trick of this business is to find the real insights
                <span> (“nuggets”) , </span>  distinguish them from all the noise, and to invest in the nuggets.
              </div>
              <div className="why-us__info-row"> Why TECTONIC Investors? Because we are <span>not only good at finding those nuggets,</span>
                but we are also uniquely <span>qualified and aligned</span> with our
                investors on the journey to a new future.
              </div>
              <div className="why-us__info-row__bold"> - TECTONIC Investors</div>
            </div>
          </div>
        </div>

      </section>}
      {isAuth && <section className="timing">
        <div className="container">
          <div className="timing-row">
            <div className="timing-column timing-column__info">
              <div className="timing-title">
                The TECTONIC Timing
              </div>
              <ul className="timing-list">
                <li className="timing-item">
                  When seeking tectonic opportunities in the investment world, what is important is not only where to
                  look, but also when.
                </li>
                <li className="timing-item">
                  From an investment perspective, a technology can be too
                  early, but it can also be too late.
                </li>
                <li className="timing-item">
                  Timing is critical ("just before the tremors are about to begin").
                </li>
              </ul>
              <div className="timing-logo">
                <div className="img-container">
                  <svg className="logo" width="32.37rem" height="5.93rem">
                    <use xlinkHref="#logo" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="timing-column timing-column__statistic">
              <div className="timing-column__statistic-img">
                <div className="img-container">
                  <img src={statistic} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}
      {isAuth && <section className="info">
        <div className="container">
          <ul className="info-list">
            <li className="info-item">
              <div className="info-row">
                <div className="info-column info-column__image">
                  <div className="info-column__image-img">
                    <div className="img-container">
                      <img src={vision} alt="" />
                    </div>
                  </div>
                </div>
                <div className="info-column info-column__info">
                  <div className="info-column__info-title">
                    The TECTONIC Vision
                  </div>
                  <ul className="info-column__info-list">
                    <li className="info-column__info-item">
                      The vision of <span>TECTONIC Investors</span>  is to
                    </li>
                    <li className="info-column__info-item">
                      Take focused, <span>smart risks</span>
                    </li>
                    <li className="info-column__info-item"> Create <span>value</span>  for our partners</li>
                    <li className="info-column__info-item"> Engage in <span>long-term investing</span>  based on required
                      tectonic changes
                      occurring in our world, not get sucked into daily mood swings as experienced by day-traders
                    </li>
                    <li className="info-column__info-item"> Create a community of investors that is
                      <span> engaged and aligned</span></li>
                  </ul>
                  <div className="info-column__info-hint">
                    The way to take <span>smart risks</span> is by <span>focusing</span> on tectonic opportunities going
                    on in the world and to understand them better than anyone else.
                  </div>
                </div>
              </div>
            </li>
            <li className="info-item">
              <div className="info-row">
                <div className="info-column info-column__image">
                  <div className="info-column__image-img">
                    <div className="img-container">
                      <img src={philosophy} alt="" />
                    </div>
                  </div>
                </div>
                <div className="info-column info-column__info">
                  <div className="info-column__info-title">
                    The TECTONIC
                    Philosophy
                  </div>
                  <ul className="info-column__info-list">
                    <li className="info-column__info-item">
                      We at <span> TECTONIC Investors </span> believe
                    </li>
                    <li className="info-column__info-item">
                      In offering a better value proposition to investors by not focusing on the annual management fee,
                      but by<span>  creating profits with purpose </span> for our partners and sharing in the upside
                    </li>
                    <li className="info-column__info-item"> In bringing out
                      <span> transformational investment opportunities
                      </span> not as an advisor, but as an <span>investment partner , </span>
                      cementing the alignment with our investors.
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>}
      {isAuth && <section className="founders">
        <div className="container">
          <div className="founders-text">
            <div className="founders-desc">
              " If you do what everyone else does, you will get what everyone gets – including not being setup for the
              future, not being prepared for tectonic opportunities and events – which will inevitably happen."
            </div>
            <div className="founders-info">
              <div>Jeff Everett & Bert van der Walt</div>

              Founders, TECTONIC Investors
            </div>
          </div>
        </div>
      </section>}
      {isAuth && <section className="client">
        <div className="container">
          <div className="client-info">
            <div className="info-column__info-title">
              The TECTONIC Client
            </div>
            <ul className="info-column__info-list">
              <li className="info-column__info-item">
                We seek investment partners in our funds who
              </li>
              <li className="info-column__info-item">
                Understand the<span> unique timing and the magnitude of the opportunity </span> and believe in the
                TECTONIC Vision
              </li>
              <li className="info-column__info-item">
                <span>  Align  </span> with TECTONIC Investor’s goals
              </li>
              <li className="info-column__info-item"><span>    Remain unsatisfied by the traditional model </span> focused on
                generating management fees for fund managers vs. multiplying capital for investors.
              </li>
            </ul>
          </div>
        </div>
      </section>}
      <section className={classNames('founders', 'founders-citate', { 'founders-citate--end': isAuth })}>
        <div className="container">
          <div className="founders-citate__bg" />
          <div className="founders-text">
            <div className="founders-desc">
              "We cannot solve our problems with the thinking we used when we created them."
            </div>
            <div className="founders-info">
              <div>Albert Einstein</div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-team">
        <div className="container">
          <div className="our-team__title">
            The TECTONIC Team
          </div>
          <ul className="our-team__list">
            <li className="our-team_item">
              <div className="our-team_row">
                <div className="our-team_column our-team_column-person">
                  <div className="our-team_column-person__info">
                    <div className="our-team_column-person__info-image">
                      <div className="img-container">
                        <img src={Jeffrey} alt="Profile" />
                      </div>
                    </div>
                    <div className="our-team_column-person__info-text">
                      <div className="our-team_column-person__info-text__title">Jeffrey Everett</div>
                      <div className="our-team_column-person__info-text__desc">Mr. Everett is the co-founder of TECTONIC
                        Investors, an investment firm focused on capitalizing on investments in unprecedented changes
                        occurring in energy transition, the circular economy, mobility, and infrastructure. Mr. Everett’s
                        investment framework was shaped by working with Sir John
                        Templeton for 20 years.
                      </div>
                    </div>
                  </div>
                  <div className="our-team_column-person__hint">
                    Mr. Everett is active in community and charitable organizations in both the US and the Bahamas.
                    Currently, Mr. Everett serves as president and a director of the Lyford Cay Foundation. Mr. Everett
                    also serves as a trustee as well as on the executive committee of the John Templeton Foundation. He
                    and his wife Gillian are the proud parents of four daughters.
                  </div>
                </div>
                <div className="our-team_column our-team_column-info">
                  <div className="our-team_column-info__title">
                    Jeffrey Everett, CFA
                    <div>Co-Founder, TECTONIC Investors</div>
                  </div>
                  <ul className="our-team_column-info__list">
                    <li className="our-team_column-info__item">
                      Portfolio manager, Tectonic Focused Fund
                    </li>
                    <li className="our-team_column-info__item">
                      CIO, Invesco Ltd. 2016-2020
                    </li>
                    <li className="our-team_column-info__item">
                      Founder, portfolio manager, Everkey Global Partners, 2007-2016
                    </li>
                    <li className="our-team_column-info__item">
                      President & CIO, Templeton Global Advisors, 2000-2007
                    </li>
                    <li className="our-team_column-info__item">
                      Portfolio manager/analyst, Templeton Global Advisors, 1989-2007
                    </li>
                    <li className="our-team_column-info__item">
                      B.S. Pennsylvania State University, 1986
                    </li>
                    <li className="our-team_column-info__item">
                      31 years investment experience
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="our-team_item">
              <div className="our-team_row">
                <div className="our-team_column our-team_column-person">
                  <div className="our-team_column-person__info">
                    <div className="our-team_column-person__info-image">
                      <div className="img-container">
                        <img src={Bert} alt="Profile" />
                      </div>
                    </div>
                    <div className="our-team_column-person__info-text">
                      <div className="our-team_column-person__info-text__title">Bert van der Walt</div>
                      <div className="our-team_column-person__info-text__desc">Bert van der Walt, CFA is a co-founder and
                        Portfolio Manager at TECTONIC Investors. He has spent his entire career identifying, tracking, and
                        capitalizing on tectonic shifts in both developing and developed markets. Prior to founding
                        TECTONIC Investors with Jeff Everett, he managed both International and Emerging Markets focused
                        funds.
                      </div>
                    </div>
                  </div>
                  <div className="our-team_column-person__hint">
                    During his investment career, Bert has excelled as part of successful investment teams in the UK,
                    South Africa, and the USA. Bert’s quantitative and risk management experience layered with broad
                    geographical expertise has helped him identify uncommon insights in all regions of the world for the
                    benefit of his clients. Bert enjoys being active and exploring the outdoors with his wife Inna and
                    their son.
                  </div>
                </div>
                <div className="our-team_column our-team_column-info">
                  <div className="our-team_column-info__title">
                    Bert van der Walt, CFA
                    <div> Co-Founder, TECTONIC Investors</div>
                  </div>
                  <ul className="our-team_column-info__list">
                    <li className="our-team_column-info__item">
                      Portfolio manager, Tectonic Focused Fund
                    </li>
                    <li className="our-team_column-info__item">
                      Portfolio manager, Invesco Ltd. 2017-2020
                    </li>
                    <li className="our-team_column-info__item">
                      Head of Emerging Markets, Mirae Asset Management, 2014-2017
                    </li>
                    <li className="our-team_column-info__item">
                      Driehaus Capital Management, 2012-2014
                    </li>
                    <li className="our-team_column-info__item">
                      Baring Asset Management, 2006-2012
                    </li>
                    <li className="our-team_column-info__item">
                      B. Comm University of Pretoria, 2002
                    </li>
                    <li className="our-team_column-info__item">
                      18 years investment experience
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Home;
