import classNames from 'classnames';

const Popup = ({
  onClose = () => {}, children, className, ...props
}) => (
  <div className={classNames('popup', { [className]: className })} {...props}>
    <div className="popup_background" onClick={onClose} />
    {children}
  </div>
);

export default Popup;
