import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsConnected } from '../redux/slices/user.slice';
import { getUserByTokenThunk } from '../redux/thunks/user.thunk';

export const useSideEffects = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(token ? getUserByTokenThunk(token) : setIsConnected());
  }, []);
};
