import React from 'react';
import { BACKGROUND_IMAGES } from '../../assets/img/background';

const Portfolio = () => (
  <>
    <section className="process">
      <div className="process-container container">
        <div className="process-row">
          <div className="process-column process-column--desc">
            <div className="process-desc">
              <div className="process-desc__caption">
                The TECTONIC
                <br />
                Investment Process
              </div>
              <div className="process-desc__info">
                <ul className="process-desc__list">
                  <li className="process-desc__item">
                    Identify the most attractive tectonic changes
                  </li>
                  <li className="process-desc__item">
                    Evaluate the magnitude, duration and timing of the changes
                  </li>
                  <li className="process-desc__item">
                    Analyze the potential profit pools related to changes
                  </li>
                </ul>
                <ul className="process-desc__list">
                  <li className="process-desc__item">
                    Research competitive positioning and strategy to capture the tectonic changes
                  </li>
                  <li className="process-desc__item">
                    Analyze financial factors and the resilience of the company’s business model
                  </li>
                  <li className="process-desc__item">
                    Estimate upside potential
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="process-column process-column--info">
            <div className="process-info">
              <div className="process-info__circle process-info__circle--top">
                <div className="process-info__circle-arrow process-info__circle-arrow-bottom">
                  <div className="img-container">
                    <svg width="2.437rem" height="3.125rem" viewBox="0 0 39 50" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19.5 -7.00247e-07L39 29.2969L28.4944 29.2969L28.4944
                            50L10.5056 50L10.5056 29.2969L-7.03785e-06 29.2969L19.5 -7.00247e-07Z"
                      />
                    </svg>

                  </div>
                </div>
                <div className="process-info__circle-info">
                  Tectonic Vision
                  TRENDS
                </div>
              </div>
              <div className="process-info__title">
                Best Ideas
              </div>
              <div className="process-info__circle process-info__circle--bottom">
                <div className="process-info__circle-arrow process-info__circle-arrow-top">
                  <div className="img-container">
                    <svg width="2.437rem" height="3.125rem" viewBox="0 0 39 50" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19.5 -7.00247e-07L39 29.2969L28.4944 29.2969L28.4944
                        50L10.5056 50L10.5056 29.2969L-7.03785e-06 29.2969L19.5 -7.00247e-07Z"
                      />
                    </svg>

                  </div>
                </div>
                <div className="process-info__circle-info">
                  Tectonic Change
                  STOCKS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="fund">
      <div className="fund-container container">
        <div className="fund-caption">
          Fund Summary
        </div>
        <div className="fund-row">
          <div className="fund-column fund-column--first">
            <ul className="fund-list">
              <li className="fund-item">
                <div className="fund-item__name">
                  Summary
                </div>
                <div className="fund-item__value">
                  Tectonic Vision Fund
                </div>
              </li>
              <li className="fund-item">
                <div className="fund-item__name">
                  Focus
                </div>
                <div className="fund-item__value">
                  Tectonic Vision
                  <ul className="fund-item__list">
                    <li className="fund-item__item">
                      Energy Transition
                    </li>
                    <li className="fund-item__item">
                      Circular Economy
                    </li>
                    <li className="fund-item__item">
                      Future Mobility
                    </li>
                    <li className="fund-item__item">
                      Innovative Infrastructure
                    </li>
                  </ul>
                </div>
              </li>
              <li className="fund-item">
                <div className="fund-item__name">
                  Geography
                </div>
                <div className="fund-item__value">
                  Global
                </div>
              </li>
              <li className="fund-item">
                <div className="fund-item__name">
                  Investment
                  Theme Horizon
                </div>
                <div className="fund-item__value">
                  5+ years
                </div>
              </li>
            </ul>
          </div>
          <div className="fund-column fund-column--second">
            <ul className="fund-list">
              <li className="fund-item">
                <div className="fund-item__name">
                  Portfolio
                </div>
                <div className="fund-item__value">
                  Up to 40 positions
                </div>
              </li>
              <li className="fund-item">
                <div className="fund-item__name">
                  Launch Date
                </div>
                <div className="fund-item__value">
                  January 2022
                </div>
              </li>
              <li className="fund-item">
                <div className="fund-item__name">
                  Portfolio Managers
                </div>
                <div className="fund-item__value">
                  Jeff Everett, Bert van der Walt
                </div>
              </li>
              <li className="fund-item">
                <div className="fund-item__name">
                  Semi-annual
                </div>
                <div className="fund-item__value">
                  Semi-annual
                </div>
              </li>
              <li className="fund-item">
                <div className="fund-item__name">
                  Subscription
                </div>
                <div className="fund-item__value">
                  Private Placement Offering
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="advertising">
      <div className="img-container">
        <img src={BACKGROUND_IMAGES.tablet} alt="Advertising background" />
      </div>
    </section>
  </>
);

export default Portfolio;
