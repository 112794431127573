import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { LOGIN_ROUTES, LOGOUT_ROUTES } from '../configs/routes.configs';
import { useSideEffects } from '../hooks/useSideEffects';
import { selectIsAuth, selectIsConnected } from '../redux/slices/user.slice';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import GlobalLoader from './Loader/GlobalLoader';
import Popups from './Popup/Popups';

const App = () => {
  const isAuth = useSelector(selectIsAuth);
  const isConnected = useSelector(selectIsConnected);
  const routes = useRoutes(isAuth ? LOGIN_ROUTES : LOGOUT_ROUTES);

  useSideEffects();

  if (!isConnected) return <GlobalLoader />;

  return (
    <div className="wrapper-outer">
      <div className="wrapper">
        <Header />
        {routes}
      </div>
      <Footer />
      <Popups />
      <ToastContainer />
    </div>
  );
};

export default App;
