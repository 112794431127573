import bottles from './bottles.jpg';
import carRow from './car-row.jpg';
import car from './car.jpg';
import carSkeleton from './car-skeleton.jpg';
import carWhite from './car-white.jpg';
import carsBg from './cars-bg.jpg';
import citate from './citate.jpg';
import cityBg from './city-bg.jpg';
import client from './client.jpg';
import colors from './colors.jpg';
import contact from './contact-bg.png';
import darkCar from './dark-car-bg.jpg';
import energyInfo from './energy-info.jpg';
import energy from './energy.jpg';
import innovation from './innovation.jpg';
import philosophy from './philosophy.jpg';
import smartCity from './smart-city.jpg';
import statistic from './statistic.png';
import tablet from './tablet.jpg';
import vision from './vision.jpg';
import water from './water.jpg';
import world from './world.png';

export const BACKGROUND_IMAGES = {
  bottles,
  carRow,
  carSkeleton,
  carWhite,
  car,
  carsBg,
  citate,
  cityBg,
  client,
  colors,
  contact,
  darkCar,
  energyInfo,
  energy,
  innovation,
  philosophy,
  smartCity,
  statistic,
  tablet,
  vision,
  water,
  world,
};
