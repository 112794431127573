import React from 'react';
import classNames from 'classnames';

const Button = ({
  children, next, className, ...props
}) => (
  <button {...props} className={classNames('property-button', { [className]: className })}>
    <span> {children}</span>
    {next && (<span className="property-button__icon">
      <span className="img-container">
        <svg width="0.5rem" height="0.875rem">
          <use xlinkHref="#arrow" />
        </svg>
      </span>
    </span>)}
  </button>
);

export default Button;
