import React from 'react';
import { useDispatch } from 'react-redux';
import { closePopup } from '../../../redux/slices/popups.slice';
import { Popup } from '../../UI';
import { POPUPS_IDS } from '../constants/popups.constants';

const { CONFIRMATION } = POPUPS_IDS;

const ConfirmationPopup = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closePopup(CONFIRMATION));
  };

  return (
    <Popup onClose={onClose}>
      <div className="confirm-popup">
        <div className="confirm-popup__info">
          THE MATERIALS HEREIN ARE INTENDED SOLELY FOR THE RECIPIENT OF THE PASSWORD AND NEITHER THE MATERIALS ON THIS
          SITE NOR THE PASSWORD MAY BE TRANSMITTED (IN ANY FORM) TO ANY OTHER PERSON WITHOUT TECTONIC INVESTORS’
          CONSENT. THIS PASSWORD-PROTECTED WEBSITE GENERALLY MAY ONLY BE ACCESSED BY EXISTING CLIENTS OF TECTONIC
          INVESTORS AND PROSPECTIVE CLIENTS OF TECTONIC INVESTORS WHO ARE QUALIFIED AS TO BOTH SUITABILITY AND NET WORTH
          AND WITH WHOM TECTONIC INVESTORS OR THE FUNDS IT MANAGES HAS A SUBSTANTIVE PREEXISTING RELATIONSHIP. THE
          INVESTMENT FUNDS AND THE MANAGERS REFERRED TO HEREIN MAY ONLY TRANSACT BUSINESS IN YOUR STATE OR JURISDICTION
          IF FIRST REGISTERED, EXCLUDED OR EXEMPTED FROM APPLICABLE BROKER-DEALER, INVESTMENT ADVISER, BD AGENT, IA REP,
          OR OTHER RELEVANT REGISTRATION REQUIREMENTS. FOLLOW-UP, INDIVIDUALIZED RESPONSES TO PERSONS IN YOUR STATE OR
          JURISDICTION BY SUCH FUNDS OR MANAGERS THAT INVOLVE EITHER THE EFFECTING OR ATTEMPTING TO EFFECT TRANSACTIONS
          IN SECURITIES, OR THE RENDERING OF PERSONALIZED INVESTMENT ADVICE FOR COMPENSATION, AS THE CASE MAY BE, WILL
          NOT BE MADE ABSENT COMPLIANCE WITH APPLICABLE BROKER-DEALER, INVESTMENT ADVISER, BD AGENT, IA REP OR OTHER
          RELEVANT REGISTRATION REQUIREMENTS, OR AN APPLICABLE EXEMPTION OR EXCLUSION.
        </div>
        <div className="confirm-popup__accept">
          <button onClick={onClose} className="confirm-popup__accept-btn" type="button">
            ok
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default ConfirmationPopup;
