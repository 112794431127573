import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectActivePopups } from '../../redux/slices/popups.slice';
import { POPUPS_CONFIGS } from './configs/popup.configs';

const Popups = () => {
  const activePopupsIds = useSelector(selectActivePopups);

  useEffect(() => {
    if (activePopupsIds.length) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'visible';
      document.body.style.overflow = 'visible';
    }
  }, [ activePopupsIds ]);

  return (
    <>
      {activePopupsIds.map((id) => {
        const PopupComponent = POPUPS_CONFIGS[id];
        return <PopupComponent key={id} />;
      })}
    </>
  );
};

export default Popups;
