import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';
import { POPUPS_IDS } from '../constants/popups.constants';
import LoginPopup from '../LoginPopup/LoginPopup';

const {
  LOGIN, CONFIRMATION,
} = POPUPS_IDS;

export const POPUPS_CONFIGS = {
  [LOGIN]: LoginPopup,
  [CONFIRMATION]: ConfirmationPopup,
};
