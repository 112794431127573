import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Navbar from '../Navbar/Navbar';
import { ICONS } from '../../assets/img/icon';
import { selectIsAuth } from '../../redux/slices/user.slice';

const { author } = ICONS;

const Footer = () => {
  const isAuth = useSelector(selectIsAuth);

  return (
    <footer className={classNames('footer', 'header-color__black', { 'footer-after-login': isAuth })}>
      <div className="container">
        <a className="footer-logo" href="/">
          <span className="img-container">
            <svg className="logo" width="22.5rem" height="4.12rem">
              <use xlinkHref="#logo" />
            </svg>
          </span>
        </a>
        <div className="footer-nav">
          <Navbar footer />
          <div className="footer-nav__copyright">
            <span className="copyright-text">Copyright © {new Date().getFullYear()} TECTONIC Investors</span>
            <NavLink className="copyright-link" to="/terms-conditions">
              Terms & Conditions
            </NavLink>
          </div>
          <div className="footer-nav__info">
            "THE INFORMATION ON THIS WEBSITE IS NOT AN OFFER TO SELL OR SOLICITATION OF AN OFFER TO BUY AN INTEREST IN ANY
            INVESTMENT FUND OR FOR THE PROVISION OF ANY INVESTMENT MANAGEMENT OR ADVISORY SERVICES. ANY SUCH OFFER OR
            SOLICITATION WILL BE MADE ONLY BY MEANS OF DELIVERY OF A CONFIDENTIAL PRIVATE OFFERING MEMORANDUM RELATING TO
            A PARTICULAR FUND OR INVESTMENT MANAGEMENT CONTRACT TO QUALIFIED INVESTORS IN THOSE JURISDICTIONS WHERE
            PERMITTED BY LAW."
          </div>
        </div>
        <div className="footer-author">
          <div className="img-container">
            <img src={author} alt="Best Draft" />
          </div>
        </div>
        {/*      <div className="footer-social">
        <a className="footer-social__icon" href="/">
          <span className="img-container">
            <svg width="0.5rem" height="1rem">
              <use xlinkHref="#facebook" />
            </svg>
          </span>
        </a>
        <a className="footer-social__icon" href="/">
          <span className="img-container">
            <svg width="1rem" height="0.812rem">
              <use xlinkHref="#twitter" />
            </svg>
          </span>
        </a>
        <a className="footer-social__icon" href="/">
          <span className="img-container">
            <svg width="0.937rem" height="1rem">
              <use xlinkHref="#linkedin" />
            </svg>
          </span>
        </a>
      </div> */}
      </div>
    </footer>
  );
};

export default Footer;
