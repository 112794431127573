import { ICONS } from '../../../assets/img/icon';

const { recycleMin, bioplastic } = ICONS;

const CircularEconomy = () => (
  <>
    <section className="property property-economy property-economy__extra content-right">
      <div className="container">
        <div className="property-info">
          <div className="property-info__title">Circular Economy</div>
          <div className="property-info__desc">
            We have before us the biggest opportunity to transform production and consumption since the First Industrial
            Revolution 250 years ago.
          </div>
        </div>
      </div>
    </section>
    <section className="recycle">
      <div className="container">
        <div className="recycle-row">
          <div className="recycle-text">
            <div className="recycle-text__row">
              Over the last 40 years, extraction of materials has
              <span> more than tripled. </span>
            </div>
            <div className="recycle-text__row">
              The total demand for resources is expected to reach
              <span> 130 B </span>
              tons by 2050, up from
              <span> 50 B </span>
              in 2014.
            </div>
            <div className="recycle-text__row">
              That’s an overuse of the Earth’s resource capacity by more than
              <span> 400%. </span>
            </div>
          </div>
          <div className="recycle-icon">
            <div className="img-container">
              <img src={recycleMin} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="keys">
      <div className="container">
        <div className="keys-row">
          <div className="keys-title">
            TECTONIC Investors will continue to identify opportunities in the following 4 key areas:
          </div>
          <div className="keys-block">
            <ul className="keys-list">
              <li className="keys-item">
                <div className="keys-item__icon">
                  <div className="img-container">
                    <svg width="4.81rem" height="4.81rem">
                      <use xlinkHref="#material" />
                    </svg>
                  </div>
                </div>
                <div className="keys-item__text">
                  New material science
                </div>
              </li>
              <li className="keys-item">
                <div className="keys-item__icon">
                  <div className="img-container">
                    <svg width="4.5rem" height="4.5rem">
                      <use xlinkHref="#manufactoring" />
                    </svg>
                  </div>
                </div>
                <div className="keys-item__text">
                  Sustainable manufacturing
                </div>
              </li>
              <li className="keys-item">
                <div className="keys-item__icon">
                  <div className="img-container">
                    <svg width="5.62rem" height="5.62rem">
                      <use xlinkHref="#natural" />
                    </svg>
                  </div>
                </div>
                <div className="keys-item__text">
                  Food, agriculture and natural systems
                </div>
              </li>
              <li className="keys-item">
                <div className="keys-item__icon">
                  <div className="img-container">
                    <svg width="4.5rem" height="4.5rem">
                      <use xlinkHref="#recycle" />
                    </svg>
                  </div>
                </div>
                <div className="keys-item__text">
                  Recycling
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="colors">
      <div className="container">
        <div className="colors-content">
          <div className="colors-text">
            <div className="colors-text__row text-row__min">
              <span>95%</span> of plastic packaging material goes unrecycled after its first use, resulting in an
              economic loss of <span>$80 to $120 B</span>
            </div>
            <div className="colors-text__row ">There will be <span>22 M</span> tons of plastic microfibers entering the
              ocean between 2015
              and 2050; in effect there will be <span>more plastics than fish </span> in the ocean by 2050**.
            </div>
            <div className="colors-text__row text-row__min">
              Although difficult to predict, conservative estimates show the
              <span> economic benefit </span>
              in the shift to a Circular Economy at around
              <br />
              <span> $4.5 T </span>
              by
              <span> 2030 </span>
              and more than
              <span> $25 T </span>
              by
              <span> 2050. </span>
            </div>
          </div>
          <div className="colors-hint">
            * measured 2016
            <br />
            ** by weight
          </div>
          <div className="colors-logo">
            <div className="img-container">
              <svg className="black-logo" width="29.3rem" height="5.375rem">
                <use xlinkHref="#logo" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="bio-plastic">
      <div className="container">
        <div className="bio-plastic__row">
          <div className="bio-plastic__icon">
            <div className="img-container">
              <img src={bioplastic} alt="" />
            </div>
          </div>
          <div className="bio-plastic__text">
            <div className="bio-plastic__text-row">
              <span>Bioplastics</span> currently represent <span>less than 1%</span> of the annual 365 M tons
              plastics capacity globally, but we believe <span>this is about to change.</span>
            </div>
            <div className="bio-plastic__text-row">
              We expect bioplastics manufacturing to <span>grow at approximately 20%
                CAGR over the next decade.</span>
            </div>
            <div className="bio-plastic__text-row">
              We at TECTONIC Investors believe that <span>PHA, a soil compostable bioplastic</span> will
              <span>grow</span> in value by a <span>factor of
                5 by 2025,</span> driven by <span> regulatory preferences</span> and <span>superior economics of bioplastics.</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="water">
      <div className="container">
        <div className="water-bg" />
        <div className="water-text">
          <div className="water-text__row">
            Since we started to produce plastics in 1950 the world has produced
            <span> 6.3 B tons, </span>
            of which
            <span> only 10% </span>
            is estimated to have been recycled.
          </div>
          <div className="water-text__row">
            Plastics take between
            <span> 400 and 600 years to decompose, </span>
            releasing
            <span> harmful chemicals </span> into our <span> air, water </span> and threatening the health of our <span> oceans. </span>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default CircularEconomy;
