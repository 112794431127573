import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpenMobileMenu: false,
};

export const UISlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openMobileMenu: (state, { payload }) => {
      state.isOpenMobileMenu = payload;
    },
  },
});

// ACTIONS
export const { openMobileMenu } = UISlice.actions;

// SELECTORS
export const selectIsOpenMobileMenu = state => state.ui.isOpenMobileMenu;

export default UISlice.reducer;
